<template>
    <!-- 未知状态转化》列表 -->
    <div id="app_add">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
                <el-button type="primary" @click="add()" size="mini">
                    增加
                </el-button>
            </div>
            <div class="head_top_title">客户未知状态转化管理</div>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px; padding:0px 15px; background-color: white;">
            
             <!-- 查询条件 -->
            <el-row id="query_condition_id" style="padding:0px 0;">
                <el-form :model="form_data" :inline="true" style="margin-bottom: 0px;" >
                    <el-form-item label="客户">
                        <el-select v-model="form_data.cpid" clearable filterable remote reserve-keyword :remote-method="getUserDataList" placeholder="请选择客户" size="mini">
                            <el-option
                                v-for="item in userDataList"
                                :key="item.cpid"
                                :label="item.cpid+'('+item.company_name+')'"
                                :value="item.cpid">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="submit_cha_xun()" size="mini">查询</el-button>
                    </el-form-item>

                </el-form>
            </el-row>

            <el-row style="overflow: auto;" :style="{height: ((content_heigth-query_condition_height)+'px')}">
                <el-table :data="tableData" :show-header="true" border style="width: 100%" size="mini" :span-method="tableSpanMethod">
                    <el-table-column prop="cpid" label="客户" min-width="70" align="center"> </el-table-column>
                    <el-table-column prop="hour_str" label="超时时间" min-width="70" align="center"> </el-table-column>
                    <el-table-column prop="rate" label="比例" min-width="70" align="center"> </el-table-column>
                    <el-table-column prop="state" label="转换后的状态" min-width="70" align="center"> </el-table-column>
                    
                    
                    <el-table-column label="操作" min-width="90" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" style="color:#f56c6c" @click="upd(scope.row)">修改</el-button>
                            <el-button type="text" size="mini" style="color:#f56c6c" @click="del(scope.row.main_id)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>

            <el-row id="pagination_id" style="padding:10px 0 0 0;text-align:center;line-height:26px;">
                <!-- 分页  layout="total, sizes, prev, pager, next, jumper"   small-->
                <el-pagination background  
                    layout="total, prev, pager, next,jumper"
                    :total="tableData_total"
                    small
                    :pager-count="5"
                    @size-change="pageSizeChange"
                    @current-change="pageCurrentChange"
                    :page-sizes="[5,10, 20, 30, 40,100]"
                     :page-size="pageSize"
                     style="white-space: normal !important;">
                </el-pagination>
            </el-row>
        </el-row>

    <!-- 增加组件 -->
    <Add :prop_change_i="prop_add_i"
        :prop_type="prop_add_type"
        :prop_item="prop_add_item"
        @result="submit_cha_xun"></Add>
    </div>
</template>
<script>
import API from '../../api/api';
import Add from './unknown_state_change_add_upd.vue';//组件 控价增加

export default {

    components: {
        Add,
    },
    
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度

            userDataList:[],//客户列表数据-请求接口获取

            form_data:{
                cpid:"",//客户账号
            },

            indexPage:1,//分页 第几页
            pageSize:10,//分页 一页的数量值
            
            tableData:[],//表格数据
            tableData_total:0,//表格数据总量
            pagination_height:40,//分页的高度
            query_condition_height:40,//查询条件的高度

            //增加
            prop_add_i:0,//客户控价增加  组件 弹出框是否可见
            prop_add_type:'add',//客户控价信息增加或修改  组件 类型  add：增加  upd:修改
            prop_add_item:{},//客户控价增加或修改  组件 参数对象

        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        
        //重置所有数据
	    Object.assign(this.$data, this.$options.data.call(this));
	    //初始化设置
        this.initSetUp();

        //查询
        this.submit_cha_xun();

        //获取客户列表数据-请求接口获取
        this.getUserDataList("");
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);
        },

        //获取客户列表数据-请求接口获取
        getUserDataList(query){
            //请求接口
            API.UserServlet({
                param: "listAISelect",
                company:"",//公司名称
                son_cpid_show:"0",//是否展示子账号 0：不展示 1:展示
                searchID:query,//输入框填写的查询条件
                loading_onoff:false
            }).then((res) => {
                if (res.recode === 0) {
                    this.userDataList = res.list;//客户列表数据--请求接口获取
                }
            });
        },

        //table表格合并行的方法
        tableSpanMethod({ row, column, rowIndex, columnIndex }) {
            var row_num = row.row_num;
            if(columnIndex == 0 || columnIndex == 1 || columnIndex == 4){
                return {rowspan: row_num,colspan: 1 };
            }
            return {rowspan: 1,colspan: 1 };
        },

        //查询
        submit_cha_xun(){
            //请求接口
            API.UserUnknownStateChangeServlet({
                param: "list",
                cpid:this.form_data.cpid,
                indexPage:this.indexPage,
                pageSize:this.pageSize,
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {

                    this.tableData = res.list;
                    this.tableData_total = res.totalNum;

                    this.$nextTick(function(){
                        //获取分页的高度
                        var pagination = document.getElementById('pagination_id');
                        if(!API.isEmtry(pagination)){
                            this.pagination_height = pagination.clientHeight;
                        }
                        //查询条件的高度
                        var query_condition = document.getElementById('query_condition_id');
                        if(!API.isEmtry(query_condition)){
                            this.query_condition_height = query_condition.clientHeight;
                        }
                    });
                } 
            });
        },
        //分页大小发生改变调用
        pageSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageSize = val;
            //查询-- 请求接口
            this.submit_cha_xun();
        },
        //分页 当前页 发生改变调用
        pageCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.indexPage = val;
            //查询-- 请求接口
            this.submit_cha_xun();
        },

        //增加
        add(){
            this.prop_add_i ++;//客户控价信息增加或修改  组件 弹出框是否可见
            this.prop_add_type = 'add';//客户控价信息增加或修改  组件 类型  add：增加  upd:修改
        },

        //修改
        upd(item){
            this.prop_add_i ++;//客户控价信息增加或修改  组件 弹出框是否可见
            this.prop_add_type = 'upd';//客户控价信息增加或修改  组件 类型  add：增加  upd:修改
            this.prop_add_item = item;//客户控价信息增加或修改  组件 参数对象
        },

        //删除
        del(main_id){
            this.$confirm('此操作将永久删除此条信息， 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                    //请求接口
                    API.UserUnknownStateChangeServlet({
                        param: "del",
                        main_id:main_id,
                    }).then((res) => {
                        if (res.recode === 0) {
                            this.$message({showClose: true,message: '恭喜你，删除成功', type: 'success'});
                            //重新加载数据
                            this.submit_cha_xun();
                        }
                    });
            }).catch(() => {//已取消删除
            });
        },

        //返回
        goBack() {
            API.router_to("/index");
        }
    }
};
</script>

<style scoped>

</style>