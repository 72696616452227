<template>
    <div>
        <!-- 未知状态转化》客户未知状态转化增加 -->
        <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
            <!-- 头 -->
            <el-row class="dialog_top">
                <span>{{cur_title}}</span>
                <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
            </el-row>

            <!-- 体 -->
            <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

                <el-row style="overflow: auto; padding-right:10px;" :style="{height: (content_heigth+'px')}">
                    <el-form  :model="form_data" label-width="155px" style="margin-bottom: 12px;">

                        <el-form-item label="客户">
                            <el-select v-model="form_data.cpid" filterable remote reserve-keyword :remote-method="getUserDataList" placeholder="请选择客户" size="mini" style="width:100%">
                                <el-option
                                    v-for="item in userDataList"
                                    :key="item.cpid"
                                    :label="item.cpid+'('+item.company_name+')'"
                                    :value="item.cpid">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="超出时间">
                            <el-select v-model="form_data.hour" filterable remote reserve-keyword  placeholder="请选择" size="mini" style="width:100%">
                                <el-option
                                    v-for="item in hour_array"
                                    :key="item"
                                    :label="item + '小时'"
                                    :value="item ">
                                </el-option>
                            </el-select>
                        </el-form-item>
               

                        <template v-for="(rate_state_item,rate_state_index) in form_data.rate_states"> 
                            <el-divider></el-divider>
                            
                            <el-form-item label="比例(%)">
                                <el-input type="text" v-model="rate_state_item.rate"
                                    oninput="value=value.replace(/[^\d.]/g,'')"
                                    placeholder="最大值不能超过100" size="mini" style="width:70%">
                                </el-input>
                            </el-form-item>

                            <el-form-item label="转换后的状态">
                                <el-input type="text" v-model="rate_state_item.state" placeholder="0表示成功，其他表示失败" size="mini" style="width:70%">
                                </el-input>

                                <!-- 增或减 -->
                                <el-button @click="rate_state_add(rate_state_index)" style="margin-left:10px" :type="rate_state_index == 0 ? 'success' : 'danger'"
                                   :icon="rate_state_index == 0 ? 'el-icon-plus' : 'el-icon-minus'" size="mini" circle></el-button>
                                
                            </el-form-item>
                            <el-divider></el-divider>

                        </template>

                        

                        <el-form-item>
                            <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                        </el-form-item>
                    </el-form>
                </el-row>
            </el-row>
        </el-dialog>
    </div>
</template>
<script>
import API from '../../api/api';

export default {
    // components: {
    // },
    props:{
        prop_change_i:0,
        prop_type:'',//类型 add:增加  upd:修改
        prop_item:{},//客户控价对象
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 
                if(this.prop_type === "upd"){//修改
                    this.cur_title = "未知状态转化-修改";
                    setTimeout(() => {
                        //获取数据
                        this.getData();
                        
                    }, 1);
                }else{
                    this.cur_title = "未知状态转化-增加";
                    
                }
            }
        }
    },
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            userDataList:[],//客户列表数据-请求接口获取

            hour_array:[],//小时数据

            cur_title:"",//页面标题

            form_data:{
                cpid:"",//客户账号
                hour:"1",//超出时间
                rate_states:[{rate:"",state:""}]//比例+转换后的状态数组
            },
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {

        //初始化设置
        this.initSetUp();

        //获取客户列表数据-请求接口获取
        this.getUserDataList("");

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){

            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);

            for(var i=1;i<=72;i++){
                this.hour_array.push(""+i);
            }
        },

        //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
        },

        getData(){
            this.form_data.cpid = this.prop_item.cpid;//客户账号
            this.form_data.hour = this.prop_item.hour;//超时时间

            //this.form_data.rate_states = this.prop_item.rate_states;//比例和状态的列表

            this.form_data.rate_states = [];//比例和状态的列表
            for(var i = 0;i<this.prop_item.rate_states.length;i++){
                var obj = this.prop_item.rate_states[i];
                var rate = obj.rate;
                var state = obj.state;
                var objNew = {"rate":rate,"state":state};
                this.form_data.rate_states.push(objNew);
            }

        },

         //获取客户列表数据-请求接口获取
         getUserDataList(query){
            //请求接口
            API.UserServlet({
                param: "listAISelect",
                company:"",//公司名称
                son_cpid_show:"0",//是否展示子账号 0：不展示 1:展示
                searchID:query,//输入框填写的查询条件
                loading_onoff:false
            }).then((res) => {
                if (res.recode === 0) {
                    this.userDataList = res.list;//客户列表数据--请求接口获取
                }
            });
        },
        //比例和状态条目增加
        rate_state_add(rate_state_index){
            if(rate_state_index == 0){
                var obj = {rate:"",state:""};
                this.form_data.rate_states.push(obj);
            }else{
                this.form_data.rate_states.splice(rate_state_index,1);//方法需要两个参数：要删除的元素的索引位置和要删除的元素数量
            }
        },

       

        //提交
        onSubmit(){
            if(API.isEmtry(this.form_data.cpid)){
                this.$message.error("请选择客户");
                return;
            }
            if(API.isEmtry(this.form_data.hour)){
                this.$message.error("请选择超出时间");
                return;
            }
            
            for(var i = 0;i<this.form_data.rate_states.length;i++){
                var obj = this.form_data.rate_states[i];
                var rate = obj.rate;
                var state = obj.state;
                
                if(API.isEmtry(rate)){
                    this.$message.error("第"+(i+1)+"行,请填写比例");
                    return;
                }
                if(API.isEmtry(state)){
                    this.$message.error("第"+(i+1)+"行,请填写状态");
                    return;
                }
            }
            var rate_states = JSON.stringify(this.form_data.rate_states);//将JSON转为字符串

            var submit_obj = {};
            if(this.prop_type === "upd"){//修改
                submit_obj.param = "upd";
                submit_obj.main_id = this.prop_item.main_id;
            }else{//增加
                submit_obj.param = "add";
            }
            submit_obj.cpid=this.form_data.cpid;
            submit_obj.hour=this.form_data.hour;
            submit_obj.rate_states=rate_states;
            
            API.UserUnknownStateChangeServlet(submit_obj).then((res) => {
                if (res.recode === 0) {
                    this.$message.success("恭喜您，提交成功");
                    //关闭
                    this.back();
                    //回调
                    this.$emit("result",'');
                }
            });
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },

    }
};

</script>

<style scoped>
/* input输入框后置元素样式 */
::v-deep  .el-input-group__append, .el-input-group__prepend{
    padding: 0 2px;
}
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}

.cur_dialog >>>.el-divider--horizontal {
  margin: 0px; 
}
</style>